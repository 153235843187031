import { LC_CONSTANTS, SUBJECT_RESTRICTION_TYPES } from './constants';
import { connectLean } from 'lean-redux';
import * as types from 'legacy/src/constants/types';

export const backupImpersonatorLC = ({ subject, educationPeriod }) => {
  const accessToken = window.localStorage.getItem(LC_CONSTANTS.AT);
  const refreshToken = window.localStorage.getItem(LC_CONSTANTS.RT);
  const expires = window.localStorage.getItem(LC_CONSTANTS.EXP);

  const impersonatorAccessToken = window.localStorage.getItem(LC_CONSTANTS.IAT);
  if (!impersonatorAccessToken || impersonatorAccessToken === 'null') {
    window.localStorage.setItem(LC_CONSTANTS.IAT, accessToken);
    window.localStorage.setItem(LC_CONSTANTS.IRT, refreshToken);
    window.localStorage.setItem(
      LC_CONSTANTS.IEXP,
      new Date(parseInt(expires, 10)).valueOf(),
    );
    window.localStorage.setItem(LC_CONSTANTS.ISUBJECT, subject);
    window.localStorage.setItem(LC_CONSTANTS.EDUCATION_PERIOD, educationPeriod);
  }
};

export const saveLocalStorageData = (
  data,
  sectionId,
  educationPeriod,
  subject,
  studentName,
  sectionName,
) => {
  const {
    access_token: accessToken,
    refresh_token: refreshToken,
    id: studentId,
  } = data;
  const expires = new Date(data.expires).valueOf();

  backupImpersonatorLC({
    subject,
    educationPeriod,
  });
  window.localStorage.setItem(LC_CONSTANTS.RO, 'true');
  window.localStorage.setItem(LC_CONSTANTS.AT, accessToken);
  window.localStorage.setItem(LC_CONSTANTS.RT, refreshToken);
  window.localStorage.setItem(
    LC_CONSTANTS.EXP,
    new Date(parseInt(expires, 10)).valueOf(),
  );
  window.localStorage.setItem(LC_CONSTANTS.STUDENT_ID, studentId);
  window.localStorage.setItem(LC_CONSTANTS.SECTION_ID, sectionId);
  window.localStorage.setItem(LC_CONSTANTS.IS_WELCOME_VIDEO_FIRST_TIME, 'true');
  window.localStorage.setItem(LC_CONSTANTS.STUDENT_NAME, studentName);
  window.localStorage.setItem(LC_CONSTANTS.SECTION_NAME, sectionName);
};

export const isImpersonating = () =>
  window.localStorage.getItem(LC_CONSTANTS.RO) === 'true';

export const getImpersonatorTokens = () => {
  const { accessTokenKey, refreshTokenKey, expiresKey } =
    getUserLocalStorageKeys();

  const accessToken = window.localStorage.getItem(accessTokenKey);
  const refreshToken = window.localStorage.getItem(refreshTokenKey);
  const expires = window.localStorage.getItem(expiresKey);

  return {
    accessToken,
    refreshToken,
    expires,
  };
};

export const updateImpersonatorAccessToken = ({ accessToken, expires }) => {
  const { accessTokenKey, expiresKey } = getUserLocalStorageKeys();

  window.localStorage.setItem(accessTokenKey, accessToken);
  window.localStorage.setItem(expiresKey, new Date(expires).valueOf());
};

export const getStudentTokens = () => ({
  accessToken: window.localStorage.getItem(LC_CONSTANTS.AT),
  refreshToken: window.localStorage.getItem(LC_CONSTANTS.RT),
  expires: window.localStorage.getItem(LC_CONSTANTS.EXP),
});

export const getImpersonatedStudentId = () =>
  window.localStorage.getItem(LC_CONSTANTS.STUDENT_ID);

export const emptyImpersonaterLocalStorage = () => {
  const { accessToken, refreshToken, expires } = getImpersonatorTokens();

  window.localStorage.setItem(LC_CONSTANTS.AT, accessToken);
  window.localStorage.setItem(LC_CONSTANTS.RT, refreshToken);
  window.localStorage.setItem(
    LC_CONSTANTS.EXP,
    new Date(parseInt(expires, 10)).valueOf(),
  );

  window.localStorage.removeItem(LC_CONSTANTS.IAT);
  window.localStorage.removeItem(LC_CONSTANTS.IRT);
  window.localStorage.removeItem(LC_CONSTANTS.IEXP);
  window.localStorage.removeItem(LC_CONSTANTS.EDUCATION_PERIOD);
  window.localStorage.removeItem(LC_CONSTANTS.STUDENT_ID);
  window.localStorage.removeItem(LC_CONSTANTS.SECTION_ID);
  window.localStorage.removeItem(LC_CONSTANTS.RO);
  window.localStorage.removeItem(LC_CONSTANTS.ISUBJECT);
  window.localStorage.removeItem(LC_CONSTANTS.ICURRENTSUBJECT);
  window.localStorage.removeItem(LC_CONSTANTS.IS_WELCOME_VIDEO_FIRST_TIME);
  window.localStorage.removeItem(LC_CONSTANTS.STUDENT_NAME);
  window.localStorage.removeItem(LC_CONSTANTS.SECTION_NAME);
};

export const isImpersonatingStudent = ({ value }) =>
  value === window.localStorage.getItem(LC_CONSTANTS.STUDENT_ID);

export const withUnitsConnectLean = connectLean({
  scope: ['units'],
  resetUnitsInternalStore() {
    this.setState({
      units: {},
    });
  },
});

export const getUserLocalStorageKeys = () => {
  let accessTokenKey;
  let refreshTokenKey;
  let expiresKey;
  if (isImpersonating()) {
    accessTokenKey = LC_CONSTANTS.IAT;
    refreshTokenKey = LC_CONSTANTS.IRT;
    expiresKey = LC_CONSTANTS.IEXP;
  } else {
    accessTokenKey = LC_CONSTANTS.AT;
    refreshTokenKey = LC_CONSTANTS.RT;
    expiresKey = LC_CONSTANTS.EXP;
  }

  return {
    accessTokenKey,
    refreshTokenKey,
    expiresKey,
  };
};

export const isImpersonatingSubjectAvailable = ({
  program,
  preapImpersonatingFlag,
}) =>
  program === types.AP || (program === types.PreAP && preapImpersonatingFlag);

export const getUnableToViewContent = (type) => {
  let description;
  switch (type) {
    case SUBJECT_RESTRICTION_TYPES.PROGRAM_AP:
      description =
        'Students use this to view resources for other courses. Student View is only available for PRE-AP Courses.';
      break;
    case SUBJECT_RESTRICTION_TYPES.PROGRAM_PREAP:
      description =
        'Students use this to view resources for other courses. Student View is only available for AP Courses.';
      break;
    case SUBJECT_RESTRICTION_TYPES.NOT_TEACHING:
      description =
        'Students use this to view resources for other courses. This can only be viewed by the teacher managing their class section.';
      break;
    default:
      description = '';
      break;
  }

  return {
    description,
    otherDescription: '',
  };
};

export const contructParamsForLDBImpersonating = () => {
  const params = {
    is_impersonating: 1,
    impersonator_subject: window.localStorage.getItem(LC_CONSTANTS.ISUBJECT),
    impersonating_student_id: window.localStorage.getItem(
      LC_CONSTANTS.STUDENT_ID,
    ),
    impersonating_section_id: window.localStorage.getItem(
      LC_CONSTANTS.SECTION_ID,
    ),
    impersonator_education_period: window.localStorage.getItem(
      LC_CONSTANTS.EDUCATION_PERIOD,
    ),
    impersonating_student_name: window.localStorage.getItem(
      LC_CONSTANTS.STUDENT_NAME,
    ),
    impersonating_section_name: window.localStorage.getItem(
      LC_CONSTANTS.SECTION_NAME,
    ),
  };
  return params;
};

export const getLDBImpersonatingHeaderDetailLC = () => ({
  studentName: window.localStorage.getItem(LC_CONSTANTS.STUDENT_NAME),
  sectionName: window.localStorage.getItem(LC_CONSTANTS.SECTION_NAME),
});

export const getSectionAndStudentName = (
  studentId,
  sectionId,
  teacherClasses = [],
) => {
  const sectionInfo = teacherClasses.find((t) => t.id === sectionId);
  if (sectionInfo) {
    const { students, name: sectionName, id: actualSectionId } = sectionInfo;
    const student = students.find((s) => s.id === studentId);
    return {
      studentName: student ? `${student.firstName} ${student.lastName}` : '',
      sectionName,
      actualSectionId,
    };
  }
  // user selected all classes
  if (!sectionInfo) {
    for (let i = 0; i < teacherClasses.length; i++) {
      const { students, name, id: actualSectionId } = teacherClasses[i];
      const student = students.find((s) => s.id === studentId);
      if (student) {
        return {
          studentName: student
            ? `${student.firstName} ${student.lastName}`
            : '',
          sectionName: name,
          actualSectionId,
        };
      }
    }
  }
  return {};
};
