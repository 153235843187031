import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import A11y from 'chameleon/ui-stack/utilities/a11y';
import PopOver from 'chameleon/ui-stack/ui/base/popover';
import { connect } from 'react-redux';
import ButtonWithIcon from 'chameleon/ui-stack/ui/buttons/button-with-icon';

class UserMenu extends Component {
  static propTypes = {
    userData: PropTypes.object.isRequired,
    showGlobalNav: PropTypes.bool,
    impersonating: PropTypes.object,
    showUnableToViewModal: PropTypes.func,
  };

  static defaultProps = {
    impersonating: {},
    showUnableToViewModal: () => {},
    showGlobalNav: false,
  };

  static displayName = 'UserMenu';

  constructor(props) {
    super(props);
    this.getMyAccountUrl = this.getMyAccountUrl.bind(this);
    this.getDURL = this.getDURL.bind(this);
    this.state = {
      DURL: this.getDURL(),
    };
  }

  getMyAccountUrl(accountAccessToken, accountRefreshToken) {
    const {
      userData: { me, isStudent },
      showGlobalNav,
    } = this.props;

    let myAccountUrl = '';
    const accountPath = showGlobalNav ? 'dashboard' : 'viewProfile';
    const DURL = window.location.href;
    switch (FEATUREFLAGS.menuLinks) {
      case 'amerit': // only by teach or coordinator
        const ssoLogin = !!me && me.ssoLogin;
        const cbLegacyAccountUrl = encodeURIComponent(
          `${CB_LEGACY_URL}/n/account#/edit`,
        );
        const redirectUrl = `${CB_LEGACY_URL}/n/auth/redirect/?next=${cbLegacyAccountUrl}&jwt=${accountAccessToken}&refresh=${accountRefreshToken}`;
        myAccountUrl =
          ssoLogin === 'SSO-FYM'
            ? `https://cbaccount.collegeboard.org/professional/${accountPath}?DURL=${DURL}`
            : redirectUrl;
        break;
      case 'apclassroom':
        if (isStudent) {
          myAccountUrl = `${ROS_FRONTEND_URL}/profile`;
        } else {
          myAccountUrl = `https://cbaccount.collegeboard.org/professional/${accountPath}?DURL=${DURL}`;
        }
        break;
      default:
        /* eslint-disable */
        console.log('No flag for menuLinks set.');
        /* eslint-enable */
        break;
    }
    return myAccountUrl;
  }

  getDURL() {
    const sessionData = sessionStorage.getItem('apc_user');
    const DURL =
      sessionData &&
      JSON.parse(sessionData) &&
      JSON.parse(sessionData)._currentPageUrl;
    return DURL;
  }

  getMySubjectsUrl() {
    const {
      userData: {
        isStudent,
        isAdmin,
        isCoordinator,
        isTeacher,
        isDemo,
        isStaff,
      },
    } = this.props;
    const isAdminOrCoordinator = isAdmin || isCoordinator;
    let mySubjectsUrl = isAdminOrCoordinator
      ? `${FYM_FRONTEND_URL}/admin`
      : `${FYM_FRONTEND_URL}/subjects/preview`;
    if (FEATUREFLAGS.menuLinks === 'apclassroom' && isStudent) {
      mySubjectsUrl = `${ROS_FRONTEND_URL}/dashboard`;
    }
    // if the user has no role (possible student with no enrollments)
    if (
      !(isAdminOrCoordinator || isStudent || isTeacher || isDemo || isStaff)
    ) {
      mySubjectsUrl = `${ROS_FRONTEND_URL}/dashboard`;
    }
    return mySubjectsUrl;
  }

  render() {
    const {
      userData: { me, isAdmin, isCoordinator, isTeacher, isStudent },
      showGlobalNav,
      impersonating,
      showUnableToViewModal,
      featureFlags,
    } = this.props;
    const isAdminOrCoordinator = isAdmin || isCoordinator;
    const accountAccessToken = window.localStorage.getItem(
      'account_access_token',
    );
    const accountRefreshToken = window.localStorage.getItem(
      'account_refresh_token',
    );
    const myAccountUrl = this.getMyAccountUrl(
      accountAccessToken,
      accountRefreshToken,
    );
    // Send the current path along so that the wizard knows where to return you to
    const path = window.location.href;
    const APStoriesUrl = `${FYM_FRONTEND_URL}/wizards/?wizardType=video&path=${path}&jwt=${accountAccessToken}&refresh=${accountRefreshToken}`;

    const mySubjectsUrl = this.getMySubjectsUrl();

    if (!me) {
      return null;
    }

    const hasAPSubject = ['ALL', 'AP'].includes(me.programs);
    const isImpersonating = impersonating.isReadOnly;

    const externalLinksClick = (event) => {
      showUnableToViewModal();
      event.preventDefault();
    };

    let extraImpersonationLinkProp = {};
    if (isImpersonating) {
      extraImpersonationLinkProp.onClick = externalLinksClick;
    }

    const className =
      'px-4.6 h-12.6 text-blue-700 hover:bg-gray-200 whitespace-nowrap flex items-center';

    const showAPStoriesInUserMenu = featureFlags.show_ap_stories_in_user_menu;

    return (
      <PopOver
        isNarrow
        accessibility={{ 'aria-label': 'User menu' }}
        trigger={(props, isOpen, ref) => (
          <ButtonWithIcon
            isGolden
            accessibility={{ title: me.firstName }}
            icon="user"
            ref={ref}
            {...props}
            data-test-id="user-menu"
          >
            {me.firstName}
          </ButtonWithIcon>
        )}
        onOpen={() => {
          this.setState({
            DURL: this.getDURL(),
          });
        }}
      >
        <div>
          <A11y>
            <a
              tabIndex={0}
              className={className}
              href={myAccountUrl}
              data-test-id="my-account-link"
              {...extraImpersonationLinkProp}
            >
              My Account
            </a>
          </A11y>
          {(isTeacher || isAdminOrCoordinator) && showGlobalNav && (
            <A11y>
              <a
                tabIndex={0}
                className={className}
                href={`${ROS_FRONTEND_URL}/courses?DURL=${encodeURIComponent(
                  this.state.DURL,
                )}`}
              >
                My Classes
              </a>
            </A11y>
          )}
          <A11y>
            <a
              tabIndex={0}
              className={className}
              data-test-id="my-ap-link"
              {...extraImpersonationLinkProp}
              href={`${ROS_FRONTEND_URL}?DURL=${encodeURIComponent(
                this.state.DURL,
              )}`}
            >
              {(isTeacher || isAdminOrCoordinator) && showGlobalNav
                ? 'AP Central'
                : 'My AP'}
            </a>
          </A11y>
          {!showGlobalNav && (
            <A11y>
              <a
                tabIndex={0}
                className={className}
                href={`${mySubjectsUrl}?DURL=${encodeURIComponent(
                  this.state.DURL,
                )}`}
              >
                My Courses
              </a>
            </A11y>
          )}
          {hasAPSubject &&
            isStudent &&
            FEATUREFLAGS.wizards &&
            showAPStoriesInUserMenu && (
              <A11y>
                <a tabIndex={0} className={className} href={APStoriesUrl}>
                  AP Stories
                </a>
              </A11y>
            )}
          <A11y>
            <Link
              tabIndex={0}
              className={className}
              to="/logout"
              onClick={() => {
                window.heap = {};
              }}
            >
              Sign Out
            </Link>
          </A11y>
        </div>
      </PopOver>
    );
  }
}

function mapStateToProps(state) {
  return {
    featureFlags: state.featureFlag,
  };
}

export default connect(mapStateToProps)(UserMenu);
