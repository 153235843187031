/* eslint-disable */
/* global FYM_BACKEND_URL */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import axios from 'axios';
import log from 'chameleon/ui-stack/utilities/log';
import _, { merge } from 'lodash';

class Tracker extends Component {
  static displayName = 'Tracker';

  static propTypes = {
    app: PropTypes.string,
    me: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    teacherSubjects: PropTypes.array,
    studentSubjects: PropTypes.array,
    router: PropTypes.shape({
      location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
    env: PropTypes.string,
    isDemo: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isCoordinator: PropTypes.bool,
    isStudent: PropTypes.bool,
    isStaff: PropTypes.bool,
    isTeacher: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    details: PropTypes.object,
  };

  static defaultProps = {
    details: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      currentSubject: '',
    };

    this.onLocationChanged = this.onLocationChanged.bind(this);
    this.getUserType = this.getUserType.bind(this);
    this.logToTelemetry = this.logToTelemetry.bind(this);
    this.setAdobeCustomData = this.setAdobeCustomData.bind(this);
    this.triggerAdobeAnalyticsPageView =
      this.triggerAdobeAnalyticsPageView.bind(this);
  }

  setAdobeCustomData() {
    const {
      app,
      me,
      teacherSubjects,
      studentSubjects,
      router: { location },
      params,
    } = this.props;
    if (me && me.initId && me.initId !== 0) {
      const userType = this.getUserType();
      const preferences = me.Preferences ? JSON.parse(me.Preferences) : {};
      const customData = {
        pageCode: '',
        pageApp: app,
        pagePath: location.pathname,
        userType,
        school: me.schoolSet[0],
        userId: me.initId,
        teacherSubjects: teacherSubjects || [],
        studentSubjects: studentSubjects || [],
        user: {
          role: userType,
        },
      };
      customData.userApOrgId = preferences.AI_CODE || null;
      if (me.schoolSet.length === 1) {
        //the id from CollegeBoard
        customData.apCourseOrgId = me.schoolSet[0].groupImportId;
      }
      if (customData.userType === 'student') {
        customData.userApStudentId = me.importId;
      }
      if (params && params.hasOwnProperty('subject')) {
        customData.apCourseId = params.subject;
      }
      _.assign(window.cbTrackData, customData);
      this.triggerAdobeAnalyticsPageView();
    }
  }

  triggerAdobeAnalyticsPageView() {
    // log to adobe analytics
    if (window._satellite) {
      try {
        window._satellite.track('cbTrack.viewInDom');
      } catch (errSatelliteTrack) {
        console.error(errSatelliteTrack);
      }
    }
  }

  logToTelemetry() {
    /**
     * If we're in impersonation mode, don't send
     * telemetry data
     */
    if (window.localStorage.getItem('isReadOnly') === 'true') {
      return true;
    }

    const {
      app,
      me,
      router: { location },
      env,
    } = this.props;

    const { details } = this.props;

    const ENV_TAGS = {
      dev: 'development',
      staging: 'staging',
      prod: 'production',
      testing: 'testing',
      perf: 'development',
      integration: 'development',
      uat: 'uat',
      optesting: 'testing',
      p2testing: 'development',
    };

    const event_type = details.event_type ? details.event_type : 'page_load';

    delete details.event_type;

    const telemetryObj = merge(
      {
        app,
        params: {
          object: location.pathname,
        },
        event_type,
        env: ENV_TAGS[env],
        user_type: this.getUserType(),
        v: 1,
      },
      details,
    );

    if ((me && me.initId && me.initId !== 0) || details) {
      axios
        .post(`https://cb-telemetry.academicmerit.com`, telemetryObj)
        .then(() => {
          if (env !== 'prod') {
            console.log(`Sent telemetry data for ${ENV_TAGS[env]}`);
          }
        })
        .catch((error) => log('error', error));
    }
  }

  onLocationChanged() {
    const { params } = this.props;
    this.logToTelemetry();
    if (
      params &&
      params.hasOwnProperty('subject') &&
      this.state.currentSubject !== params.subject
    ) {
      this.setState(
        {
          currentSubject: params.subject,
        },
        () => this.setAdobeCustomData(),
      );
    }
  }

  getUserType() {
    /**
     * Return string representation of user type based on roles
     *
     * If user has multiple roles priority becomes: staff > admin > coordinator > teacher
     */
    const {
      isDemo,
      isAdmin,
      isCoordinator,
      isStudent,
      isStaff,
      isTeacher,
      me,
    } = this.props;

    if (isDemo) {
      return 'demo';
    } else if (isStaff) {
      return 'staff';
    } else if (isAdmin) {
      return 'admin';
    } else if (isCoordinator) {
      return 'coordinator';
    } else if (isTeacher) {
      return 'teacher';
    } else if (isStudent) {
      return 'student';
    } else if (me) {
      return me.userRole?.filter((role) => role !== 'pl_user')?.join(', ');
    }
    // Should never get this far
    return 'unknown';
  }

  componentDidMount() {
    /**
     * Always log to cb-telemetry irrespective of the split results.
     */
    this.logToTelemetry();
    /**
     * Log adobe custom metrics.
     */
    this.setAdobeCustomData();

    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentWillReceiveProps(props) {
    /**
     * Tracks location change on path change without reload
     *
     * This method is called twice: first with the old path (when transition starts)
     * and again with the next path (when transition is completed). Only tracking the final path.
     */
    if (props.location.pathname === this.props.location.pathname) {
      this.onLocationChanged();
    }
  }

  render() {
    return null;
  }
}

export default withRouter(Tracker);
